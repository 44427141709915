<script>
export default {
  name: "AppFooter",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
  i18n: {
    messages: {
      en: {
        footerText: [
          "Trading financial assets such as stocks, indices, commodities, cryptocurrency and trading pairs offers the opportunity for high profits, but carries significant risk of loss. Before you start trading, it is highly recommended that you research the specifics of each instrument. Make sure you are fully aware of all the risks associated with this activity. <br/>Remember that past performance does not guarantee future success and trading always involves certain risks. We strongly advise you to allocate your investment portfolio to minimise risk and pay attention to the current economic environment and geopolitical factors that may affect the financial markets.",
        ],
      },
      ru: {
        footerText: [
          "Торговля финансовыми активами, такими как акции, индексы, сырьевые товары, криптовалюта и торговые пары, предоставляет возможность для получения высоких прибылей, однако сопряжена с значительными рисками потерь. Прежде чем начать торговлю, настоятельно рекомендуется изучить особенности каждого инструмента. Убедитесь, что вы полностью осознаете все риски, связанные с данной деятельностью. <br/>Помните, что прошлые результаты не гарантируют будущий успех, и торговля всегда сопряжена с определенными рисками. Наша компания настоятельно советует распределить инвестиционный портфель для снижения рисков и уделить внимание текущей экономической обстановке и геополитическим факторам, которые могут воздействовать на финансовые рынки.",
        ],
      },
      fr: {
        footerText: [
          "La négociation d'actifs financiers tels que les actions, les indices, les matières premières, les cryptomonnaies et les paires de devises offre la possibilité de réaliser des profits élevés, mais comporte un risque de perte important. Avant de commencer à négocier, il est fortement recommandé de se renseigner sur les spécificités de chaque instrument. Assurez-vous d'être pleinement conscient de tous les risques associés à cette activité. N'oubliez pas que les performances passées ne garantissent pas le succès futur et que le trading comporte toujours certains risques. Nous vous conseillons vivement de répartir votre portefeuille d'investissement de manière à minimiser les risques et de prêter attention à l'environnement économique actuel et aux facteurs géopolitiques susceptibles d'affecter les marchés financiers. ",
        ],
      },
      de: {
        footerText: [
          "Der Handel mit Finanzanlagen wie Aktien, Indizes, Rohstoffen, Kryptowährungen und Handelspaaren bietet die Chance auf hohe Gewinne, birgt aber auch ein erhebliches Verlustrisiko. Bevor Sie mit dem Handel beginnen, sollten Sie sich unbedingt über die Besonderheiten der einzelnen Instrumente informieren. Vergewissern Sie sich, dass Sie sich über alle mit dieser Tätigkeit verbundenen Risiken im Klaren sind.<br/>Denken Sie daran, dass frühere Erfolge keine Garantie für künftige Erfolge sind und der Handel immer gewisse Risiken birgt. Wir raten Ihnen dringend, Ihr Anlageportfolio so zusammenzustellen, dass das Risiko minimiert wird, und das aktuelle wirtschaftliche Umfeld und die geopolitischen Faktoren, die die Finanzmärkte beeinflussen können, zu beachten.",
        ],
      },
    },
  },
};
</script>

<template>
  <footer class="footer">
    <div data-aos="fade-up" class="container">
      <div class="row row-gap-40">
        <div class="col-xl-7">
          <div class="row row-gap-20">
            <div class="col-md-4">
              <div class="footer__logo mb-20">
                <router-link :to="$localePath({ name: 'Home' })">
                  <img :src="require('@/assets/img/logo-white.svg')" alt="" />
                </router-link>
              </div>
              <div class="row row-gap-20">
                <div class="col-6 col-md-12">
                  <p class="mb-10">
                    <a
                      href="mailto:support@dcamgmt.com"
                      class="footer__soc-link"
                    >
                      <span class="ic-mail link-icon"></span>
                      E-mail
                    </a>
                  </p>

                  <a
                    href="tg://resolve?domain=dca_supportbot"
                    class="footer__soc-link"
                  >
                    <span class="ic-send link-icon"></span>
                    Telegram
                  </a>
                </div>
                <div class="col-6 col-md-12">
                  <div class="footer__menu">
                    <router-link
                      :to="$localePath({ name: 'Privacy' })"
                      class="menu__item"
                    >
                      {{ $t("privacy") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="footer__title h3">
                <router-link :to="$localePath({ name: 'Markets' })">
                  {{ $t("menu.markets") }}
                </router-link>
              </div>
              <div class="footer__menu">
                <router-link
                  :to="$localePath({ name: 'Currency' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.currency") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Indices' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.indices") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Stock' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.stock") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Forex' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.forex") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Commodities' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.commodities") }}
                </router-link>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="footer__title h3">
                {{ $t("company") }}
              </div>
              <div class="footer__menu">
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="menu__item"
                >
                  {{ $t("menu.about") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Safety' })"
                  class="menu__item"
                >
                  {{ $t("menu.safe") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="menu__item"
                >
                  {{ $t("menu.platform") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="menu__item"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="footer__text">
            <p v-for="item in $t('footerText')" :key="item">
              <span v-html="item"></span>
            </p>
          </div>
        </div>
        <div class="col-12">
          <p class="copy">DCA © {{ getYear }}, all rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  background: $black;
  color: $white;
  padding: 50px 0 60px;
}
.footer__text {
  font-size: 14px;
  color: $gray2;
}
.copy {
  color: $gray2;
  font-size: 16px;
}
.footer__logo {
  display: inline-block;
  width: 158px;
}
.footer__soc-link {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
  color: $white;

  .link-icon {
    font-size: 24px;
    color: $green;
    line-height: 1;
  }

  &:hover {
    color: $green;
  }
}
.footer__title {
  color: $white;
  margin-bottom: 20px;

  a {
    color: $white;
  }
}
.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.menu__item {
  color: $green;
  &:hover {
    text-decoration: underline;
  }
}
</style>
